import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { server } from "../../API";
import Preview from "./Preview";
import { RemoveScroll } from "react-remove-scroll";

const axios = require("axios");

export default function InputModal({ isOpen, handleToggleInput, selectedRow }) {
  const [date, setDate] = useState("");
  function handleDate(event) {
    setDate(event.target.value);
  }

  const [designer, setDesigner] = useState("");
  function handleDesigner(event) {
    setDesigner(event.target.value);
  }

  const [fabricNo, setFabricNo] = useState("");
  function handleFabricNo(event) {
    setFabricNo(event.target.value);
  }

  const [width, setWidth] = useState("");
  function handleWidth(event) {
    setWidth(event.target.value);
  }

  const [washing, setWashing] = useState("");
  function handleWashing(event) {
    setWashing(event.target.value);
  }

  const [ingredient, setIngredient] = useState("");
  function handleIngredient(event) {
    setIngredient(event.target.value);
  }

  const [styleNo, setStyleNo] = useState("");
  function handleStyleNo(event) {
    setStyleNo(event.target.value);
  }

  const [classification, setClassification] = useState("");
  function handleClassification(event) {
    setClassification(event.target.value);
  }

  const [season, setSeason] = useState("");
  function handleSeason(event) {
    setSeason(event.target.value);
  }

  const [explanation, setExplanation] = useState("");
  function handleExplanation(event) {
    setExplanation(event.target.value);
  }

  const [instructions, setInstructions] = useState("");
  function handleInstructions(event) {
    setInstructions(event.target.value);
  }

  // image states
  const [front, setFront] = useState();
  const [back, setBack] = useState();
  const [refOne, setRefOne] = useState();
  const [refTwo, setRefTwo] = useState();
  const [refThree, setRefThree] = useState();
  const [refFour, setRefFour] = useState();

  const ImageInput = ({ setFile, name }) => {
    function imageInputHandler() {
      // setFile : function - [setFront, setBack]
      selectFile(setFile);
    }

    return (
      <input
        type="button"
        value={name || "Upload Photo"}
        onClick={imageInputHandler}
      />
    );
  };

  function selectFile(setFile) {
    // setFile: function - [setFront, setBack]
    // resolves file open dialog issues (for when uploading imgs)
    const fileInputEl = document.createElement("input");
    fileInputEl.type = "file";
    fileInputEl.accept = "image/*";
    fileInputEl.style.display = "none";
    document.body.appendChild(fileInputEl);

    fileInputEl.addEventListener("input", function (e) {
      console.log("input changed:", e);
      if (e.target.files && e.target.files.length > 0) {
        let file = e.target.files[0];
        if (file.type.match(/image.*/)) {
          const reader = new FileReader();
          reader.onload = function (e) {
            let img = new Image();
            img.src = reader.result;
            console.log("setting img");
            setFile(img.src);
          };
          reader.readAsDataURL(file);
        } else {
          setFile("File not supported!");
        }
      }

      document.body.removeChild(fileInputEl);
    });

    try {
      fileInputEl.dispatchEvent(new MouseEvent("click"));
    } catch (e) {
      console.log("Mouse Event error:\n" + e.message);
    }
  }

  function handleSubmit() {
    if (!date || !designer || !fabricNo || !styleNo) {
      let dateString = !!date ? "" : "\n    Date";
      let designerString = !!designer ? "" : "\n    Designer";
      let fabricString = !!fabricNo ? "" : "\n    Fabric No #";
      let styleString = !!styleNo ? "" : "\n    Style No #";
      alert(
        `Missing Data Fields: ${dateString} ${designerString} ${fabricString} ${styleString}`
      );
      document.getElementById("design-inputmodal").scrollTo(0, 0);
    } else {
      axios({
        method: "post",
        url: `${server}/design`,
        data: {
          date: date,
          designer: designer,
          fabricNo: fabricNo,
          width: width,
          washing: washing,
          ingredient: ingredient,
          styleNo: styleNo,
          classify: classification,
          season: season,
          explain: explanation,
          workInstructions: instructions,
          refOne: refOne,
          refTwo: refTwo,
          refThree: refThree,
          refFour: refFour,
          front: front,
          back: back,
        },
      })
        .then(function (response) {
          console.log(response);
        })
        .then(function () {
          handleToggleInput();
          clearData();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  async function handleEdit() {
    console.log("handle Edit");
    if (!date || !designer || !fabricNo || !styleNo) {
      let dateString = !!date ? "" : "\n    Date";
      let designerString = !!designer ? "" : "\n    Designer";
      let fabricString = !!fabricNo ? "" : "\n    Fabric No #";
      let styleString = !!styleNo ? "" : "\n    Style No #";
      alert(
        `Missing Data Fields: ${dateString} ${designerString} ${fabricString} ${styleString}`
      );
      document.getElementById("design-inputmodal").scrollTo(0, 0);
    } else {
      axios({
        method: "put",
        url: `${server}/design/${selectedRow._id}`,
        data: {
          date: date,
          designer: designer,
          fabricNo: fabricNo,
          width: width,
          washing: washing,
          ingredient: ingredient,
          styleNo: styleNo,
          classify: classification,
          season: season,
          explain: explanation,
          workInstructions: instructions,
          refOne: refOne,
          refTwo: refTwo,
          refThree: refThree,
          refFour: refFour,
          front: front,
          back: back,
        },
      })
        .then((response) => {
          console.log(response);
        })
        .then(function () {
          handleToggleInput();
          clearData();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function handleClosing() {
    clearData();
    handleToggleInput();
  }

  const didSelectRow = !!Object.keys(selectedRow).length;
  const editOrSubmit = didSelectRow ? handleEdit : handleSubmit;

  // scrolls to top on open and clears all data from input on opening
  // if selecting a row, it imports data isntead.
  useEffect(() => {
    if (!isOpen) {
      console.log("closing input");
    } else if (!!Object.keys(selectedRow).length) {
      console.log("importing");
      importSelectedRow(selectedRow);
    } else {
      console.log("opening input");
      clearData();
    }
    document.getElementById("design-inputmodal").scrollTo(0, 0);
  }, [isOpen, selectedRow]);

  function importSelectedRow(selectedRow) {
    setDate(selectedRow.date);
    setDesigner(selectedRow.designer);
    setFabricNo(selectedRow.fabricNo);
    setWidth(selectedRow.width);
    setWashing(selectedRow.washing);
    setIngredient(selectedRow.ingredient);
    setStyleNo(selectedRow.styleNo);
    setClassification(selectedRow.classify);
    setSeason(selectedRow.season);
    setExplanation(selectedRow.explain);
    setInstructions(selectedRow.workInstructions);
    setFront(selectedRow.front);
    setBack(selectedRow.back);
    setRefOne(selectedRow.refOne);
    setRefTwo(selectedRow.refTwo);
    setRefThree(selectedRow.refThree);
    setRefFour(selectedRow.refFour);
  }

  function clearData() {
    setDate("");
    setDesigner("");
    setFabricNo("");
    setWidth("");
    setWashing("");
    setIngredient("");
    setStyleNo("");
    setClassification("");
    setSeason("");
    setExplanation("");
    setInstructions("");
    setFront("");
    setBack("");
    setRefOne("");
    setRefTwo("");
    setRefThree("");
    setRefFour("");
  }

  return (
    <RemoveScroll enabled={isOpen}>
      <ModalContainer
        tabIndex="-1"
        role="dialog"
        aria-labelledby="bannerformmodal"
        aria-hidden="true"
        isOpen={isOpen}
        id="design-inputmodal"
      >
        <div className="modal-header">
          <Title>Form</Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={() => handleClosing()}
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          <form encType="multipart/form-data">
            <div className="form-group">
              <Label htmlFor="date-input" className="col-4">
                Date<Required> *required</Required>
              </Label>
              <div className="col-10">
                <input
                  className="form-control"
                  style={{ width: "330px" }}
                  type="date"
                  id="date-input"
                  onChange={handleDate}
                  value={date}
                  placeholder="2021-01-01"
                />
              </div>
              <Label htmlFor="designer-name-input" className="col-4">
                Designer<Required> *required</Required>
              </Label>
              <div className="col-10">
                <input
                  className="form-control"
                  style={{ width: "330px" }}
                  type="text"
                  id="designer-name-input"
                  onChange={handleDesigner}
                  value={designer}
                  placeholder="Danny Kim"
                />
              </div>
              <Label htmlFor="fabric-num-input" className="col-4">
                Fabric No<Required> *required</Required>
              </Label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="text"
                  style={{ width: "330px" }}
                  id="fabric-num-input"
                  onChange={handleFabricNo}
                  value={fabricNo}
                  placeholder="XXX000"
                />
              </div>
              <Label htmlFor="style-no-input" className="col-4">
                Style No<Required> *required</Required>
              </Label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="text"
                  id="style-no-input"
                  style={{ width: "330px" }}
                  onChange={handleStyleNo}
                  value={styleNo}
                  placeholder="XXX000"
                />
              </div>
              <div className="col-10">
                <Label htmlFor="front-image">Front</Label>
                <ImageInput name="Upload Front View" setFile={setFront} />
              </div>
              <div className="col-10">
                <Preview marginTop="16px" src={front} />
              </div>
              <div className="col-10">
                <Label htmlFor="back-image">Back</Label>
                <ImageInput name="Upload Back View" setFile={setBack} />
              </div>
              <div className="col-10">
                <Preview marginTop="16px" src={back} />
              </div>
              <Label htmlFor="width-input" className="col-4">
                Width
              </Label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="text"
                  style={{ width: "330px" }}
                  id="width-input"
                  onChange={handleWidth}
                  value={width}
                  placeholder="00 /00"
                />
              </div>
              <Label htmlFor="washing-input" className="col-4">
                Washing
              </Label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="text"
                  style={{ width: "330px" }}
                  id="washing-input"
                  onChange={handleWashing}
                  value={washing}
                  placeholder="Enter description"
                />
              </div>
              <div className="col-10">
                <Label htmlFor="ingredient-textarea">Ingredient</Label>
                <textarea
                  className="form-control"
                  id="ingredient-textarea"
                  rows="3"
                  style={{ width: "330px" }}
                  onChange={handleIngredient}
                  value={ingredient}
                  placeholder="Enter description"
                ></textarea>
              </div>
              <Label htmlFor="classify-input" className="col-4">
                Classify
              </Label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="text"
                  id="classify-input"
                  style={{ width: "330px" }}
                  onChange={handleClassification}
                  value={classification}
                  placeholder="Enter classification"
                />
              </div>
              <Label htmlFor="season-input" className="col-4">
                Season
              </Label>
              <div className="col-10">
                <input
                  className="form-control"
                  type="text"
                  id="season-input"
                  style={{ width: "330px" }}
                  onChange={handleSeason}
                  value={season}
                  placeholder="Enter season"
                />
              </div>
              <div className="col-10">
                <Label htmlFor="explain-textarea">Explain</Label>
                <textarea
                  className="form-control"
                  id="explain-textarea"
                  style={{ width: "330px" }}
                  onChange={handleExplanation}
                  value={explanation}
                  rows="3"
                ></textarea>
              </div>
              <div className="col-10">
                <Label htmlFor="work-instructions-textarea">
                  Work Instructions
                </Label>
                <textarea
                  className="form-control"
                  id="work-instructions-textarea"
                  rows="3"
                  style={{ width: "330px" }}
                  onChange={handleInstructions}
                  value={instructions}
                ></textarea>
              </div>
              <div className="col-3">
                <Label size="12px">Reference(s)</Label>
                <WorkInstructionLoadContainer>
                  <ImageInput name="Upload WI #1" setFile={setRefOne} />
                  <ImageInput name="Upload WI #2" setFile={setRefTwo} />
                  <ImageInput name="Upload WI #3" setFile={setRefThree} />
                  <ImageInput name="Upload WI #4" setFile={setRefFour} />
                </WorkInstructionLoadContainer>
              </div>
              <div className="col-10">
                <WorkInstructionReferences>
                  <Preview marginTop="24px" src={refOne} previewNo="WI #1" />
                  <Preview marginTop="24px" src={refTwo} previewNo="WI #2" />
                </WorkInstructionReferences>
                <WorkInstructionReferences>
                  <Preview src={refThree} previewNo="WI #3" />
                  <Preview src={refFour} previewNo="WI #4" />
                </WorkInstructionReferences>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-blue"
            data-dismiss="modal"
            onClick={editOrSubmit}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn btn-blue"
            data-dismiss="modal"
            onClick={handleClosing}
          >
            Cancel
          </button>
        </div>
      </ModalContainer>
    </RemoveScroll>
  );
}

const ModalContainer = styled.div`
  z-index: 20;
  background-color: white;
  min-width: 600px;
  width: 50%;
  height: 86%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  margin-left: ${(props) => (props.isOpen ? "0%" : "3%")};
  opacity: ${(props) => (props.isOpen ? "100%" : "0%")};
  transition: opacity 0.3s, margin-left 0.3s;
  pointer-events: ${(props) => (props.isOpen ? "initial" : "none")};
`;

const Label = styled.label`
  font-size: ${(props) => props.size || "initial"};
  margin: 12px 0px;
  width: 100%;
  font-weight: bold;
`;

const Title = styled.h3`
  font-size: 24px;
`;

const WorkInstructionLoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  height: 120px;
`;

const WorkInstructionReferences = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-self: start;
`;

const Required = styled.span`
  color: green;
  font-weight: regular;
  font-size: 8px;
`;
