import React from "react";
import { Container } from "./Modal.styled";

export function Modal({ children, center, height, width }) {
  return (
    <Container height={height} width={width} center={center}>
      {children}
    </Container>
  );
}
