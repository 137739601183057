import React, { useState } from "react";
import {
  Container,
  Section,
  Title,
  Subtitle,
  Text,
  Row,
  ButtonsContainer,
  ThreePartFormContainer,
  OneThirdFormContainer,
  InputWrapper,
  DetailTypesContainer,
  DetailTypesLabel,
  DetailDataRowsContainer,
  DetailAddWrapper,
  ReferenceContainer,
  BoxingTypesContainer,
  BoxingTypesLabel,
} from "./CreatePurchaseOrder.styled";
import { Link, useHistory } from "react-router-dom";

// components
import Button from "../../Components/Atomic/Atom/button";
import useInput from "../../Components/Atomic/Atom/useInput";

// icons
import { MdAddCircleOutline, MdImage, MdDelete } from "react-icons/md";

export function CreatePurchaseOrder() {
  let history = useHistory();

  // Product inputs
  const [issuedBy, issuedByInput] = useInput({ type: "text" });
  const [exfactoryDate, exfactoryDateInput] = useInput({ type: "text" });
  const [poDateReceived, poDateReceivedInput] = useInput({
    type: "text",
    key: "poDateReceived",
  });
  const [poNo, poNoInput] = useInput({ type: "text" });
  const [styleNo, styleNoInput] = useInput({ type: "text" });
  const [fabricNo, fabricNoInput] = useInput({ type: "text" });
  const [payment, paymentInput] = useInput({ type: "text" });

  // Vendor inputs
  const [vendorStreetAddressOne, vendorStreetAddressOneInput] = useInput({
    type: "text",
  });
  const [vendorStreetAddressTwo, vendorStreetAddressTwoInput] = useInput({
    type: "text",
  });
  const [vendorCity, vendorCityInput] = useInput({ type: "text" });
  const [vendorState, vendorStateInput] = useInput({ type: "text" });
  const [vendorZip, vendorZipInput] = useInput({ type: "text" });
  const [vendorCountry, vendorCountryInput] = useInput({ type: "text" });
  const [vendorPhone, vendorPhoneInput] = useInput({ type: "text" });

  // Ship To inputs
  const [shipStreetAddressOne, shipStreetAddressOneInput] = useInput({
    type: "text",
  });
  const [shipStreetAddressTwo, shipStreetAddressTwoInput] = useInput({
    type: "text",
  });
  const [shipCity, shipCityInput] = useInput({ type: "text" });
  const [shipState, shipStateInput] = useInput({ type: "text" });
  const [shipZip, shipZipInput] = useInput({ type: "text" });
  const [shipCountry, shipCountryInput] = useInput({ type: "text" });
  const [shipPhone, shipPhoneInput] = useInput({ type: "text" });

  // Details Input
  const [detailRows, setdetailRows] = useState(3);

  function DetailsInput(numberOfRows) {
    // numberOfRows : integer - number of rows
    function DetailRow({ last, index }) {
      // Detail Inputs
      const [detailColor, detailColorInput] = useInput({
        type: "text",
        containerStyle: styles.colorInput,
      });
      const [detailPcs, detailPcsInput] = useInput({
        type: "text",
        containerStyle: styles.pcsInput,
      });
      const [detailXS, detailXSInput] = useInput({
        type: "text",
        containerStyle: styles.xsInput,
      });
      const [detailS, detailSInput] = useInput({
        type: "text",
        containerStyle: styles.sInput,
      });
      const [detailM, detailMInput] = useInput({
        type: "text",
        containerStyle: styles.mInput,
      });
      const [detailL, detailLInput] = useInput({
        type: "text",
        containerStyle: styles.lInput,
      });
      const [detailXL, detailXLInput] = useInput({
        type: "text",
        containerStyle: styles.xlInput,
      });
      const [detailUnitPrice, detailUnitPriceInput] = useInput({
        type: "text",
        containerStyle: styles.unitPriceInput,
      });
      const [detailAmount, detailAmountInput] = useInput({
        type: "text",
        containerStyle: styles.amountInput,
      });
      return (
        <DetailTypesContainer noMargin>
          <>{detailColorInput}</>
          <>{detailPcsInput}</>
          <>{detailXSInput}</>
          <>{detailSInput}</>
          <>{detailMInput}</>
          <>{detailLInput}</>
          <>{detailXLInput}</>
          <>{detailUnitPriceInput}</>
          <>{detailAmountInput}</>
          {index === last ? <MdDelete /> : null}
        </DetailTypesContainer>
      );
    }

    let detailRows = [];
    for (let index = 0; index < numberOfRows; index++) {
      detailRows.push(<DetailRow last={detailRows.length - 1} index={index} />);
    }
    return detailRows.map((item) => {
      return item;
    });
  }

  // Boxing input
  const [boxingLabel, boxingLabelInput] = useInput({
    type: "text",
    containerStyle: styles.boxingLabel,
  });
  const [packingType, packingTypeInput] = useInput({
    type: "text",
    containerStyle: styles.packType,
  });
  const [priceTicket, priceTicketInput] = useInput({
    type: "text",
    containerStyle: styles.priceTicket,
  });
  const [boxSize, boxSizeInput] = useInput({
    type: "text",
    containerStyle: styles.boxSize,
  });
  const [boxLimit, boxLimitInput] = useInput({
    type: "text",
    containerStyle: styles.boxLimit,
  });

  return (
    <Container>
      <Section>
        <Title>Purchase Order Form</Title>
        <ButtonsContainer>
          <Button label="Back" onClick={history.goBack} />
        </ButtonsContainer>
      </Section>
      <Section noPaddingTop noPaddingBottom borderBottom>
        <ThreePartFormContainer>
          <OneThirdFormContainer>
            <Subtitle>Product</Subtitle>
            <InputWrapper>
              <Text textAlign="left">Issued By</Text>
              {issuedByInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">Ex-factory date</Text>
              {exfactoryDateInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">P.O. Date Received</Text>
              {poDateReceivedInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">P.O. No.</Text>
              {poNoInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">Style No.</Text>
              {styleNoInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">Fabric No.</Text>
              {fabricNoInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">Payment</Text>
              {paymentInput}
            </InputWrapper>
          </OneThirdFormContainer>
          <OneThirdFormContainer>
            <Subtitle>Vendor</Subtitle>
            <InputWrapper>
              <Text textAlign="left">Street Address</Text>
              {vendorStreetAddressOneInput}
              {vendorStreetAddressTwoInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">City</Text>
              {vendorCityInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">State / Province / Region</Text>
              {vendorStateInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">ZIP Code</Text>
              {vendorZipInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">Country</Text>
              {vendorCountryInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">Phone Number</Text>
              {vendorPhoneInput}
            </InputWrapper>
          </OneThirdFormContainer>
          <OneThirdFormContainer noBorder>
            <Subtitle>Ship To</Subtitle>
            <InputWrapper>
              <Text textAlign="left">Street Address</Text>
              {shipStreetAddressOneInput}
              {shipStreetAddressTwoInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">City</Text>
              {shipCityInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">State / Province / Region</Text>
              {shipStateInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">ZIP Code</Text>
              {shipZipInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">Country</Text>
              {shipCountryInput}
            </InputWrapper>
            <InputWrapper>
              <Text textAlign="left">Phone Number</Text>
              {shipPhoneInput}
            </InputWrapper>
          </OneThirdFormContainer>
        </ThreePartFormContainer>
      </Section>
      <Section borderBottom direction="column">
        <Subtitle>Details</Subtitle>
        <DetailTypesContainer>
          <DetailTypesLabel width="364px">Color</DetailTypesLabel>
          <DetailTypesLabel width="96px">Pcs</DetailTypesLabel>
          <DetailTypesLabel width="55px">XS</DetailTypesLabel>
          <DetailTypesLabel width="55px">S</DetailTypesLabel>
          <DetailTypesLabel width="55px">M</DetailTypesLabel>
          <DetailTypesLabel width="55px">L</DetailTypesLabel>
          <DetailTypesLabel width="55px">XL</DetailTypesLabel>
          <DetailTypesLabel width="113px">Unit Price</DetailTypesLabel>
          <DetailTypesLabel width="113px">Amount</DetailTypesLabel>
        </DetailTypesContainer>
        <DetailDataRowsContainer>
          {DetailsInput(detailRows)}
        </DetailDataRowsContainer>
        <DetailAddWrapper>
          <Button
            label="Add"
            icon={<MdAddCircleOutline color="rgba(0,0,0,0.54)" size="18px" />}
          />
        </DetailAddWrapper>
      </Section>
      <Section direction="column" borderBottom>
        <Subtitle>Reference</Subtitle>
        <ReferenceContainer>
          <Button
            label="Upload"
            icon={
              <MdImage color="rgba(0,0,0,0.54)" label="Upload" size="18px" />
            }
          />
        </ReferenceContainer>
      </Section>
      <Section direction="column" borderBottom>
        <Subtitle>Boxing</Subtitle>
        <BoxingTypesContainer>
          <BoxingTypesLabel width="364px">Label</BoxingTypesLabel>
          <BoxingTypesLabel width="134px">Pack Type</BoxingTypesLabel>
          <BoxingTypesLabel width="134px">Price Ticket</BoxingTypesLabel>
          <BoxingTypesLabel width="134px">Box Size</BoxingTypesLabel>
          <BoxingTypesLabel width="261px">Box Limit</BoxingTypesLabel>
        </BoxingTypesContainer>
        <BoxingTypesContainer>
          {boxingLabelInput}
          {packingTypeInput}
          {priceTicketInput}
          {boxSizeInput}
          {boxLimitInput}
        </BoxingTypesContainer>
      </Section>
      <Section>
        <ButtonsContainer>
          <Button label="Submit" />
          <Button label="Cancel" onClick={history.goBack} />
        </ButtonsContainer>
      </Section>
    </Container>
  );
}

const styles = {
  colorInput: {
    width: "364px",
  },
  pcsInput: {
    width: "96px",
  },
  xsInput: {
    width: "55px",
  },
  sInput: {
    width: "55px",
  },
  mInput: {
    width: "55px",
  },
  lInput: {
    width: "55px",
  },
  xlInput: {
    width: "55px",
  },
  unitPriceInput: {
    width: "113px",
  },
  amountInput: {
    width: "113px",
  },
  boxingLabel: {
    width: "364px",
  },
  packType: {
    width: "134px",
  },
  priceTicket: {
    width: "134px",
  },
  boxSize: {
    width: "134px",
  },
  boxLimit: {
    width: "261px",
  },
};
