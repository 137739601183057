import React, { useState, useEffect, useCallback } from "react";
import Row from "./Row";
import InputModal from "./InputModal";
import PopUpModal from "../../Components/Atomic/Atom/popup-modal";
import Loading from "./Loading";
import Preview from "./Preview";
import DeleteModal from "./DeleteModal";
import { server } from "../../API";
import { Container, Dimmer } from "./Design.styled";
import { RemoveScroll } from "react-remove-scroll";

const axios = require("axios");

export function Design() {
  const [isData, setData] = useState([]); // data of nested {} in []

  // get data from row
  const [selectedRow, setSelectRow] = useState({});

  // on selection of image, zoom in
  const [selectImage, setSelectImage] = useState("");
  const [toggleImage, setToggleImage] = useState(false);
  const handlePopupImageToggle = useCallback(() => {
    setToggleImage(!toggleImage);
  }, [toggleImage]);

  // get data from db
  const [isLoading, setIsLoading] = useState(true);
  function getDesignData() {
    axios({
      method: "get",
      url: `${server}/design`,
    })
      .then(function (response) {
        setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    console.log("Getting design data");
    // initialization
    async function initialize() {
      await getDesignData();
      setIsLoading(false);
    }
    initialize();

    // interval retrieval
    const getDataInterval = setInterval(() => {
      getDesignData();
    }, 3000);
    return () => clearInterval(getDataInterval);
  }, []);

  // map data into components
  const [dataTable, setDataTable] = useState(null); // mapped isData
  const [toggleInput, setToggleInput] = useState(false);
  const handleToggleInput = useCallback(() => {
    // handle toggle input modal
    setToggleInput(!toggleInput);
  }, [toggleInput]);

  function handleNewPost() {
    setToggleInput(!toggleInput);
    setSelectRow({});
  }

  // when deleting an item, a second confirmation modal shows up.
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = useCallback(() => {
    // handle toggle input modal
    setDeleteConfirmation(!deleteConfirmation);
  }, [deleteConfirmation]);

  useEffect(() => {
    function renderData() {
      setDataTable(
        isData
          .slice(0)
          .reverse()
          .map((item, index) => (
            <Row
              key={`clothing-${index}`}
              data={item}
              handleToggleInput={handleToggleInput}
              handlePopUpImageToggle={handlePopupImageToggle}
              setSelectRow={setSelectRow}
              setSelectImage={setSelectImage}
              handleDeleteConfirmation={handleDeleteConfirmation}
            />
          ))
      );
    }
    renderData();
  }, [
    isData,
    handleToggleInput,
    handlePopupImageToggle,
    handleDeleteConfirmation,
  ]);

  const PreviewSelection = (selectImage) => {
    if (Array.isArray(selectImage)) {
      return selectImage.map((item, index) => {
        return (
          <Preview
            noPreview={!item}
            width="20vw"
            src={item}
            key={`${item}-${index}`}
          />
        );
      });
    } else {
      return <Preview hover original src={selectImage} />;
    }
  };

  function dimmerOnClick() {
    setToggleImage(false);
    setToggleInput(false);
    setDeleteConfirmation(false);
  }

  return (
    <Container className="container">
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th>Date</th>
            <th rowSpan="3">Front</th>
            <th rowSpan="3">Back</th>
            <th>Fabric No</th>
            <th rowSpan="3">Ingredient</th>
            <th>Style No</th>
            <th rowSpan="3">Explain</th>
            <th rowSpan="3">Work Instructions</th>
            <th rowSpan="3">
              <button onClick={handleNewPost}>New</button>
            </th>
            <th rowSpan="3">Actions</th>
          </tr>
          <tr>
            <th rowSpan="2">Designer</th>
            <th>Width</th>
            <th>Classify</th>
          </tr>
          <tr>
            <th>Washing</th>
            <th>Season</th>
          </tr>
        </thead>
        {isLoading ? (
          <Loading />
        ) : isData.length ? (
          dataTable
        ) : (
          <tbody>
            <tr>
              <td rowSpan="5" colSpan="10">
                <p>{`Currently ${isData.length} items in the database`}</p>
              </td>
            </tr>
          </tbody>
        )}
        <tbody></tbody>
      </table>
      <InputModal
        handleToggleInput={handleToggleInput}
        isOpen={toggleInput}
        selectedRow={selectedRow}
      />
      <PopUpModal toggle={deleteConfirmation}>
        <RemoveScroll enabled={deleteConfirmation}>
          <DeleteModal
            handleDeleteConfirmation={handleDeleteConfirmation}
            data={selectedRow}
          />
        </RemoveScroll>
      </PopUpModal>
      <PopUpModal setToggle={handlePopupImageToggle} toggle={toggleImage}>
        <RemoveScroll enabled={toggleImage}>
          {PreviewSelection(selectImage)}
        </RemoveScroll>
      </PopUpModal>
      <Dimmer
        isOpen={toggleImage || toggleInput || deleteConfirmation}
        onClick={dimmerOnClick}
      />
    </Container>
  );
}
