import React from "react";
import styled from "styled-components";
import { useAuth } from "..";
import { useHistory } from "react-router-dom";

export default function SignoutButton() {
  let history = useHistory();
  // let location = useLocation();
  let auth = useAuth();

  // let { from } = location.state || { from: { pathname: "/" } };
  // let login = () => {
  //   auth.signin(() => {
  //     history.replace(from);
  //   });
  // };

  return auth.user ? (
    <LoginIndicatorWrapper>
      <Welcome>Welcome {auth.user.toLowerCase()}!</Welcome>
      <LoginButton
        onClick={() => {
          auth.signout(() => {
            history.push("/");
          });
        }}
      >
        Sign out
      </LoginButton>
    </LoginIndicatorWrapper>
  ) : null;
}

const LoginIndicatorWrapper = styled.div`
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginButton = styled.button`
  outline-offset: 2px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  max-width: 100%;
  position: relative;
  display: inline-block;
  outline-style: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: white;
  background-color: #006fb2;
  border: solid 1px #00aeff;
  padding: 10px 30px;
  border-radius: 3px;
  min-width: 121px;
  transition: color 200ms, background-color 200ms, border-color 200ms;
  :hover {
    background-color: #007bc6;
    border: solid 1px #79d4ff;
  }
  :focus {
    outline: none;
  }
`;

const Welcome = styled.p`
  width: 180px;
  text-align: center;
`;
