import styled from "styled-components";

const width = "90%";
const xPadding = "24px";
const yPadding = "32px";

export const Container = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.div`
  width: ${width};
  padding-top: ${(props) => (props.noPaddingTop ? "0px" : `${yPadding}`)};
  padding-bottom: ${(props) => (props.noPaddingBottom ? "0px" : `${yPadding}`)};
  margin: auto;
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  margin-top: ${(props) => props.marginTop || "initial"};
`;

export const FixedSection = styled(Section)`
  top: ${(props) => props.top || "initial"};
  margin-top: null;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
`;

export const Text = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
  font-size: 12px;
  color: black;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 36px;
`;

export const DataTypesContainer = styled(Row)`
  height: 52px;
  width: 100%;
  border-bottom: 1px solid #bcbcbc;
  margin: auto;
`;

export const DataTypesWrapper = styled(Row)`
  height: 100%;
  width: 90%;
  padding-left: ${xPadding};
  display: flex;
  gap: 26px;
`;

export const DataTypeLabel = styled(Row)`
  justify-content: center;
  width: ${(props) => props.width || "144px"};
  overflow: auto;
  height: 100%;
  :hover {
    cursor: pointer;
  }
`;

export const DataRowsContainer = styled(Column)`
  width: 100%;
  min-height: 52px;
  margin: auto;
  overflow: auto;
`;
export const DataRowWrapper = styled(Column)`
  width: 100%;
  align-items: start;
`;

export const DataRowInnerWrapper = styled(Row)`
  height: 40px;
  width: 100%;
  padding-left: ${xPadding};
  gap: 26px;
  border-bottom: ${(props) =>
    props.borderBottom ? "1px solid #EFEFEF" : "null"};
  :hover {
    background-color: #f5f5f5;
  }
`;

export const DataRowLabel = styled(DataTypeLabel)`
  :hover {
    cursor: initial;
  }
`;

export const ButtonsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 12px;
`;
