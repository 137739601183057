export const data = [
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Kevin",
    typeTwo: "2021-04-30",
    typeThree: "DAA018",
    typeFour: "FAA013",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Vincent",
    typeTwo: "2021-04-29",
    typeThree: "DAA017",
    typeFour: "FAA012",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
  {
    typeOne: "Danny",
    typeTwo: "2021-04-28",
    typeThree: "DAA016",
    typeFour: "FAA011",
    typeFive: "40% DEPOSIT / 60% NET 30",
  },
];

export default data;
