import React from "react";
import styled from "styled-components";
import Modal from "../../Components/Atomic/Atom/modal";
import Preview from "./Preview";
import { server } from "../../API";

const axios = require("axios");

export default function DeleteModal({ handleDeleteConfirmation, data }) {
  function deleteRow() {
    axios
      .delete(`${server}/design/${data._id}`)
      .then(function (response) {
        console.log("Deleting row");
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleDelete() {
    deleteRow();
  }

  async function remove() {
    await handleDelete();
    handleDeleteConfirmation();
  }

  function no() {
    handleDeleteConfirmation();
  }
  return (
    <Modal width="500px" height="340px">
      <Wrapper>
        <ConfirmationWrapper>
          <P>Do you want to delete this item?</P>
        </ConfirmationWrapper>
        <DataWrapper>
          <Datapoint>
            <div></div>
            <p>Designer:</p>
            <Data>{data.designer}</Data>
          </Datapoint>
          <Datapoint>
            <div></div>
            <p>Date:</p>
            <Data>{data.date}</Data>
          </Datapoint>
          <Datapoint>
            <div></div>
            <p>Style #:</p>
            <Data>{data.styleNo}</Data>
          </Datapoint>
          <Datapoint>
            <div></div>
            <p>Fabric #:</p>
            <Data>{data.fabricNo}</Data>
          </Datapoint>
        </DataWrapper>
        <PreviewWrapper>
          <Preview height="100px" src={data.front} />
          <Preview height="100px" src={data.back} />
        </PreviewWrapper>
        <ButtonContainer>
          <Button delete onClick={remove}>
            Delete
          </Button>
          <Button onClick={no}>No</Button>
        </ButtonContainer>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  flex: 0.6;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: grey;
  width: 100%;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
`;

const P = styled.p`
  color: white;
`;

const DataWrapper = styled.div`
  display: flex;
  flex: 1.5;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

const Datapoint = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Data = styled.p`
  padding-top: 10px;
  padding-bottom: 10px;
  color: green;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Button = styled.button`
  min-width: 90px;
  color: ${(props) => (props.delete ? "white" : "black")};
  background-color: ${(props) => (props.delete ? "red" : "lightgrey")};
  border: none;
  border-radius: 12px;
  height: 30px;
  margin: 7px;
  :hover {
    cursor: pointer;
  }
`;
