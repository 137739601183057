import styled from "styled-components";

export const Container = styled.div`
  min-height: ${(props) => props.height || "480px"};
  min-width: ${(props) => props.width || "800px"};
  background-color: white;
  border-radius: 12px;
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "initial")};
  align-self: center;
  overflow: hidden;
`;
