import React, { useState } from "react";
import {
  Container,
  Section,
  FixedSection,
  Text,
  Title,
  DataTypesContainer,
  DataTypesWrapper,
  DataTypeLabel,
  DataRowsContainer,
  DataRowWrapper,
  DataRowInnerWrapper,
  DataRowLabel,
  ButtonsContainer,
} from "./PurchaseOrder.styled";

import { Link } from "react-router-dom";

// Components
import useInput from "../../Components/Atomic/Atom/useInput";
import Button from "../../Components/Atomic/Atom/button";

// icons
import {
  MdKeyboardArrowDown,
  MdHome,
  MdAddCircleOutline,
  MdPrint,
} from "react-icons/md";

// mock data
import data from "./data";

export function PurchaseOrder() {
  const [count, setCount] = useState(0);
  const increaseCount = () => setCount(count + 1);
  const decreaseCount = () => setCount(count - 1);
  const [value, valueInput] = useInput({ type: "text" });

  const dataTypes = [
    { key: "Issued by" },
    { key: "Ex-Factory Date" },
    { key: "Style No." },
    { key: "Fabric No." },
    { key: "Payment" },
  ];

  function DataTypesHeader({ dataTypes }) {
    // children : array - array of DataType objects

    function DataType({ dataType }) {
      // dataType : object - DataType object
      return (
        <DataTypeLabel>
          <Text>{dataType}</Text>
          <MdKeyboardArrowDown />
        </DataTypeLabel>
      );
    }

    const DataTypes = dataTypes.map((item, index) => {
      return <DataType dataType={item.key} key={`${item}-${index}`} />;
    });

    return (
      <DataTypesContainer>
        <DataTypesWrapper>{DataTypes}</DataTypesWrapper>
      </DataTypesContainer>
    );
  }

  function DataRows({ data }) {
    function DataRow({ dataRow, last }) {
      // dataRow : object - Data object
      return (
        <DataRowInnerWrapper borderBottom={last}>
          <DataRowLabel>
            <Text>{dataRow.typeOne}</Text>
          </DataRowLabel>
          <DataRowLabel>
            <Text>{dataRow.typeTwo}</Text>
          </DataRowLabel>
          <DataRowLabel>
            <Text>{dataRow.typeThree}</Text>
          </DataRowLabel>
          <DataRowLabel>
            <Text>{dataRow.typeFour}</Text>
          </DataRowLabel>
          <DataRowLabel>
            <Text>{dataRow.typeFive}</Text>
          </DataRowLabel>
        </DataRowInnerWrapper>
      );
    }

    const DataBody = data.map((item, index) => {
      if (index == data.length - 1) {
        return <DataRow dataRow={item} key={`${item}-${index}`} />;
      }
      return <DataRow dataRow={item} key={`${item}-${index}`} last />;
    });

    return (
      <DataRowsContainer>
        <DataRowWrapper>{DataBody}</DataRowWrapper>
      </DataRowsContainer>
    );
  }

  return (
    <Container>
      <FixedSection top="80px">
        <Title>Purchase Orders</Title>
        <ButtonsContainer>
          <Link to="/purchaseorder/form">
            <Button
              label="Add"
              right
              icon={
                <MdAddCircleOutline size={"18px"} color={"rgba(0,0,0,0.54)"} />
              }
            />
          </Link>
          <Button
            label="Print"
            right
            icon={<MdPrint size={"18px"} color={"rgba(0,0,0,0.54)"} />}
          />
          <Link to="/">
            <Button
              label="Back to Home"
              containerStyle={styles.backToHome}
              left
              icon={<MdHome size={"18px"} color={"rgba(0,0,0,0.54)"} />}
            />
          </Link>
        </ButtonsContainer>
      </FixedSection>
      <FixedSection noPaddingTop noPaddingBottom top="160px">
        <DataTypesHeader dataTypes={dataTypes} />
      </FixedSection>
      <Section marginTop="180px" direction="column">
        <DataRows data={data} />
      </Section>
    </Container>
  );
}

const styles = {
  backToHome: {
    width: "131px",
  },
};
