import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  box-shadow: 0.3px 0.3px 3px 1px #b6b6b6;
`;

export const LoginContainer = styled.div`
  width: 300px;
  background-color: #ebeeef;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
`;

export const LoginHeader = styled.div`
  display: flex;
  flex: 1;
  background-color: #728791;
  justify-content: center;
  align-items: center;
`;

export const LoginBody = styled.div`
  display: flex;
  flex: 4;
  background-color: #f3f3f3;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-right: 18px;
`;

export const Title = styled.h1`
  text-align: center;
  color: white;
  font-size: 28px;
  font-weight: bold;
`;

export const Label = styled.label`
  width: 34px;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 200px;
  height: 40px;
  vertical-align: middle;
  box-sizing: border-box;
  padding: 0px 12px;
  border: ${(props) =>
    props.status ? "1px solid #d1383d" : "1px solid #bbc0c4"};
  border-radius: 3px;
  line-height: 24px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: ${(props) => props.marginLeft || "10px"};
  transition: border-color 0.2s, box-shadow 0.2s;
  :focus {
    border-color: ${(props) => (props.status ? "#d1383d" : "#00aeff")};
    background-color: #f8fafa;
    outline: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LoginButton = styled.button`
  outline-offset: 2px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  max-width: 100%;
  position: relative;
  display: inline-block;
  outline-style: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: white;
  background-color: #006fb2;
  border: solid 1px #00aeff;
  padding: 10px 30px;
  border-radius: 3px;
  width: 200px;
  margin-top: 20px;
  margin-left: 44px;
  transition: color 200ms, background-color 200ms, border-color 200ms;
  :hover {
    background-color: #007bc6;
    border: solid 1px #79d4ff;
  }
  :not {
    outline-style: none;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled.img`
  padding: 10px 30px;
`;

export const Warning = styled.p`
  background-color: #f3f3f3;
  font-size: 12px;
  color: #d1383d;
  text-align: center;
  line-height: 32px;
  width: 100%;
  height: 32px;
  padding-left: 36px;
`;
