import React, { useState } from "react";
import { server } from "../../API";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../Context/auth";
import {
  Container,
  LoginContainer,
  LoginBody,
  Input,
  InputContainer,
  LoginButton,
  Label,
  ImageContainer,
  LogoContainer,
  Wrapper,
  Warning,
} from "./Login.styled";

import Logo from "../../assets/angels_welcome_market.png";

const axios = require("axios");

export function Login() {
  const [, setCookie] = useCookies(["JWT"]); // not using cookies so there is a hanging comma :/

  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();
  let { from } = location.state || { from: { pathname: "/" } };
  let login = () => {
    auth.signin(() => {
      history.replace(from);
    }, ID);
  };

  const [loginStatus, setLoginStatus] = useState(true);
  const [ID, setID] = useState("");
  function handleID(event) {
    setID(event.target.value);
  }

  const [PW, setPW] = useState("");
  function handlePW(event) {
    setPW(event.target.value);
  }

  function handleLogin() {
    axios({
      method: "post",
      url: `${server}/user/login`,
      data: {
        userid: ID.toLowerCase(),
        password: PW,
      },
    })
      .then(function (response) {
        console.log("id exists:", response);
        if (response.data === true) {
          axios({
            method: "post",
            url: `${server}/api/issuetoken`,
            data: {
              userid: ID,
            },
          }).then(function (response) {
            console.log("=== issuing token === \n", response.data);
            setCookie("JWT", response.data, { path: "/" });
            login();
          });
        } else {
          setLoginStatus(false);
          console.log(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function LoginSuccess({ loginStatus }) {
    if (!loginStatus)
      return <Warning>The ID or password is incorrect.</Warning>;
    else return <Warning />;
  }

  return (
    <Wrapper>
      <Container>
        <LoginContainer>
          <LoginBody>
            <InputContainer>
              <Label htmlFor="username">ID</Label>
              <Input
                autoCapitalize="off"
                status={!loginStatus}
                type="text"
                id="username"
                name="username"
                onChange={handleID}
                value={ID}
              />
              <br />
              <br />
            </InputContainer>
            <LoginSuccess loginStatus={loginStatus} />
            <InputContainer>
              <Label htmlFor="password">PW</Label>
              <Input
                autoCapitalize="off"
                type="password"
                id="password"
                name="password"
                onChange={handlePW}
                value={PW}
              />
              <br />
              <br />
            </InputContainer>
            <InputContainer>
              <LoginButton onClick={handleLogin}>Login</LoginButton>
            </InputContainer>
          </LoginBody>
        </LoginContainer>
        <ImageContainer>
          <LogoContainer src={Logo} />
        </ImageContainer>
      </Container>
    </Wrapper>
  );
}
