import styled from "styled-components";

export const Container = styled.div`
  margin-top: 80px;
  padding-top: 12px;
  padding-bottom: 50px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Dimmer = styled.div`
  z-index: 10;
  background-color: black;
  opacity: ${(props) => (props.isOpen ? "50%" : "0%")};
  pointer-events: ${(props) => (props.isOpen ? "initial" : "none")};
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  transition: opacity 0.3s;
`;
export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
