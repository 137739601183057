import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 20px 0px;
  padding-left: 30px;
  flex-direction: row;
  height: 80px;
`;

export const Indicator = styled.p`
  text-align: center;
  position: relative;
  color: red;
  top: 80px;
`;
