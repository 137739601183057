import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.toggle ? "100%" : "0%")};
  transition: opacity 0.2s;
  z-index: 20;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: ${(props) => (props.toggle ? "auto" : "none")};
`;
