import React from "react";
import styled from "styled-components";

export default function Preview({
  src,
  previewNo,
  width,
  height,
  marginTop,
  zIndex,
  original,
  noPreview,
  hover,
}) {
  if (src) {
    // console.log(Object.keys(src));
    return (
      <Image
        original={original}
        marginTop={marginTop}
        width={width}
        height={height}
        src={src}
        zIndex={zIndex}
        hover={hover}
      />
    );
  } else
    return (
      <NoImage marginTop={marginTop} width={width} noPreview={noPreview}>
        {previewNo || "Preview"}
      </NoImage>
    );
}

const Image = styled.img`
  width: ${(props) => (props.original ? "initial" : props.width || "200px")};
  height: ${(props) => (props.original ? "580px" : props.height || "initial")};
  object-fit: contain;
  margin: 4px 4px;
  margin-top: ${(props) => props.marginTop || "4px"};
  display: flex;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => props.zIndex || 0};
  :hover {
    cursor: ${(props) => (props.hover ? "pointer" : "intial")};
  }
`;

const NoImage = styled.div`
  height: ${(props) => props.width || "200px"};
  width: ${(props) => props.width || "200px"};
  border-style: solid;
  border-color: grey;
  border-width: 2px;
  margin: 4px 4px;
  margin-top: ${(props) => props.marginTop || "4px"};
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.noPreview ? "none" : "flex")};
`;
