import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import { server } from "../../../API";
import { useCookies } from "react-cookie";
import { useAuth } from "..";

export default function PrivateRoute({ children, ...rest }) {
  const [cookies] = useCookies();
  let auth = useAuth();

  useEffect(() => {
    function authenticate() {
      axios({
        method: "get",
        url: `${server}/api/authenticate`,
        headers: {
          authorization: `Bearer ${cookies.JWT}`,
        },
      })
        .then(function (response) {
          console.log("=== Checking Token ===");
          console.log("authenticated:", response);
          console.log("auth:", auth);
          let isAuthenticated = response.data;
          if (!isAuthenticated) {
            auth.signout(() => {
              alert("Login Session Expired. Please login again.");
            });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    const getAuthentication = setInterval(() => {
      authenticate();
    }, 30000);

    return () => clearInterval(getAuthentication);
  });

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
