import React from "react";
import styled from "styled-components";

export default function Loading() {
  console.log("Loading");
  return (
    <tbody>
      <tr>
        <Td rowSpan="5" colSpan="10">
          Loading
        </Td>
      </tr>
    </tbody>
  );
}

const Td = styled.td`
  text-align: center;
`;
