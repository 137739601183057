import { createContext, useContext, useState } from "react";

export const authContext = createContext();

// Subscribes to the closest authContext Provider
export function useAuth() {
  return useContext(authContext);
}

const session = {
  isAuthenticated: false,
  signin(callback) {
    session.isAuthenticated = true;
    callback();
  },
  signout(callback) {
    session.isAuthenticated = false;
    callback();
  },
};

export function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (callback, userid) => {
    return session.signin(() => {
      setUser(userid);
      callback();
    });
  };

  const signout = (callback) => {
    return session.signout(() => {
      setUser(null);
      callback();
    });
  };

  return {
    user,
    signin,
    signout,
  };
}
