import "./App.css";

import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Home from "./Pages/home";
import Design from "./Pages/design";
import Details from "./Pages/design/details";
import Production from "./Pages/production";
import Inventory from "./Pages/inventory";
import Sales from "./Pages/sales";
import Accounting from "./Pages/accounting";
import Report from "./Pages/report";
import Login from "./Pages/login";
import PurchaseOrder from "./Pages/purchase-order";
import CreatePurchaseOrder from "./Pages/create-purchase-order";

import { Wrapper, Nav } from "./App.styled";

// Contextual Components
import PrivateRoute from "./Context/auth/ContextualComponent/PrivateRoute";
import SignoutButton from "./Context/auth/ContextualComponent/SignoutButton";
import ProvideAuth from "./Context/auth/ContextualComponent/ProvideAuth";

function App() {
  // react-router redirect (auth)

  return (
    <ProvideAuth>
      <Wrapper>
        <Router>
          <div>
            <Nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="collapse navbar-collapse">
                <div className="navbar-nav">
                  <Link to="/" className="navbar-brand">
                    Twinklyn
                  </Link>
                  <Link to="/design" className="nav-item nav-link">
                    Design
                  </Link>
                  <Link to="/purchaseorder" className="nav-item nav-link">
                    P.O.
                  </Link>
                  <Link to="/production" className="nav-item nav-link">
                    Production
                  </Link>

                  <Link to="/inventory" className="nav-item nav-link">
                    Inventory
                  </Link>

                  <Link to="/sales" className="nav-item nav-link">
                    Sales
                  </Link>

                  <Link to="/accounting" className="nav-item nav-link">
                    Accounting
                  </Link>

                  <Link to="/report" className="nav-item nav-link">
                    Report
                  </Link>
                </div>
              </div>
              <SignoutButton />
            </Nav>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <PrivateRoute exact path="/">
                <Home />
              </PrivateRoute>
              <PrivateRoute exact path="/design">
                <Design />
              </PrivateRoute>
              <PrivateRoute path="/design/:itemId">
                <Details />
              </PrivateRoute>
              <PrivateRoute exact path="/purchaseorder/form">
                <CreatePurchaseOrder />
              </PrivateRoute>
              <PrivateRoute exact path="/purchaseorder">
                <PurchaseOrder />
              </PrivateRoute>
              <PrivateRoute exact path="/production">
                <Production />
              </PrivateRoute>
              <PrivateRoute exact path="/production/:itemId">
                <Details />
              </PrivateRoute>
              <PrivateRoute exact path="/inventory">
                <Inventory />
              </PrivateRoute>
              <PrivateRoute exact path="/sales">
                <Sales />
              </PrivateRoute>
              <PrivateRoute exact path="/accounting">
                <Accounting />
              </PrivateRoute>
              <PrivateRoute exact path="/report">
                <Report />
              </PrivateRoute>
            </Switch>
          </div>
        </Router>
      </Wrapper>
    </ProvideAuth>
  );
}

export default App;
