import React from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Preview from "./Preview";

export default function Row({
  data,
  handleToggleInput,
  handlePopUpImageToggle,
  setSelectRow,
  setSelectImage,
  handleDeleteConfirmation,
}) {
  function handleDelete() {
    setSelectRow(data);
    handleDeleteConfirmation();
  }

  function handleEdit() {
    handleToggleInput();
    setSelectRow(data);
  }

  function handleImageSelectFront() {
    setSelectImage(data.front);
    handlePopUpImageToggle();
  }

  function handleImageSelectBack() {
    setSelectImage(data.back);
    handlePopUpImageToggle();
  }

  function handleImageWorkInstructions() {
    setSelectImage([data.refOne, data.refTwo, data.refThree, data.refFour]);
    handlePopUpImageToggle();
  }

  return (
    <tbody>
      <tr>
        <th>{data.date}</th>
        <th rowSpan="3">
          <Wrapper onClick={handleImageSelectFront}>
            <Preview
              hover
              noPreview={!data.front}
              width="60px"
              src={data.front}
            />
          </Wrapper>
        </th>
        <th rowSpan="3">
          <Wrapper onClick={handleImageSelectBack}>
            <Preview
              hover
              noPreview={!data.back}
              width="60px"
              src={data.back}
            />
          </Wrapper>
        </th>
        <th>{data.fabricNo}</th>
        <th rowSpan="3">{data.ingredient}</th>
        <th>{data.styleNo}</th>
        <th rowSpan="3">{data.explain}</th>
        <th rowSpan="3" onClick={handleImageWorkInstructions}>
          {data.workInstructions}
          <Wrapper>
            <Preview
              width="60px"
              height="50px"
              noPreview={!data.refOne}
              src={data.refOne}
              hover
            />
            <Preview
              width="60px"
              height="50px"
              noPreview={!data.refTwo}
              src={data.refTwo}
              hover
            />
          </Wrapper>
          <Wrapper>
            <Preview
              width="60px"
              height="50px"
              noPreview={!data.refThree}
              src={data.refThree}
              hover
            />
            <Preview
              width="60px"
              height="50px"
              noPreview={!data.refFour}
              src={data.refFour}
              hover
            />
          </Wrapper>
        </th>
        <th rowSpan="3">
          <Wrapper>
            <Link to={{ pathname: `/production/${data._id}`, state: data }}>
              <Button icon="cut" />
            </Link>
          </Wrapper>
        </th>
        <th rowSpan="3">
          <Wrapper>
            <Button icon="edit" onClick={handleEdit} />
            <Button icon="trash" onClick={handleDelete} />
          </Wrapper>
        </th>
      </tr>
      <tr>
        <th rowSpan="2">{data.designer}</th>
        <th>{data.width}</th>
        <th>{data.classify}</th>
      </tr>
      <tr>
        <th>{data.washing}</th>
        <th>{data.season}</th>
      </tr>
    </tbody>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
