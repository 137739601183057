import React from "react";
import { Container } from "./PopupModal.styled";

export function PopupModal({ children, toggle, setToggle }) {
  // toggle : boolean - open or close modal
  return (
    <Container onClick={setToggle} toggle={toggle}>
      {children}
    </Container>
  );
}
