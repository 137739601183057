import styled from "styled-components";

const width = "90%";
const xPadding = "24px";
const yPadding = "32px";

export const Container = styled.div`
  margin-top: 80px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.div`
  width: ${(props) => (props.noWidth ? "max-content" : `${width}`)};
  padding-top: ${(props) => (props.noPaddingTop ? "0px" : `${yPadding}`)};
  padding-bottom: ${(props) => (props.noPaddingBottom ? "0px" : `${yPadding}`)};
  margin: auto;
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  margin-top: ${(props) => props.marginTop || "initial"};
  border-bottom: ${(props) =>
    props.borderBottom ? "1px solid #efefef" : "null"};
  overflow: auto;
`;

export const FixedSection = styled(Section)`
  top: ${(props) => props.top || "initial"};
  margin-top: null;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
`;

export const Text = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  color: black;
  text-align: ${(props) => props.textAlign || "center"};
`;

export const Title = styled.h1`
  font-size: 36px;
`;

export const Subtitle = styled.h2`
  font-size: 24px;
`;

export const ButtonsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ThreePartFormContainer = styled(Row)`
  width: 100%;
  height: 564px;
  justify-content: center;
`;

export const OneThirdFormContainer = styled(Column)`
  flex: 1;
  border-right: ${(props) => (props.noBorder ? "none" : "1px solid #EFEFEF")};
  justify-content: start;
  height: 100%;
  align-items: start;
  gap: 24px;
  padding: 0px 36px;
`;

export const InputWrapper = styled(Column)`
  width: 100%;
  align-items: start;
  gap: 12px;
`;

export const DetailTypesContainer = styled(Row)`
  width: max-content;
  margin-top: ${(props) => (props.noMargin ? "0px" : `${yPadding}`)};
  gap: 10px;
`;

export const DetailTypesLabel = styled(Text)`
  width: ${(props) => props.width || "initial"};
  text-align: left;
  font-size: 16px;
`;

export const DetailDataRowsContainer = styled(Column)`
  justify-content: space-evenly;
  align-items: start;
  gap: 4px;
  margin-top: 8px;
`;

export const DetailAddWrapper = styled(Row)`
  width: 364px;
  justify-content: center;
  margin-top: 8px;
`;

export const ReferenceContainer = styled(Row)`
  justify-content: center;
  height: 200px;
  width: 100%;
`;

export const BoxingTypesContainer = styled(DetailTypesContainer)``;

export const BoxingTypesLabel = styled(DetailTypesLabel)``;
