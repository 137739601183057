import React, { useState } from "react";

export function useInput({ type, containerStyle }) {
  const [value, setValue] = useState("");
  const Input = (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      type={type}
      style={containerStyle}
    />
  );
  return [value, Input];
}
